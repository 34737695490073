import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { minHeightForMenuPage, paddingForMenuPage } from "../defines";
import {
    List,
    Segment
} from "semantic-ui-react"

const AnimalsPage = ({ mobile }) => (
    <>
        <SEO title="Animals" />
        <Segment
            textAlign="center"
            vertical
            inverted
            style={{ minHeight: minHeightForMenuPage(mobile), padding: paddingForMenuPage(mobile) }}
        >
            <List divided inverted relaxed="very">
                <List.Item as="a" onClick={() => navigate("/visualizations/animals/animals-slaughtered")}>
                    <List.Content>
                        <List.Header>Animals slaughtered</List.Header>
                        <List.Description>Yearly per-country chart showing the number of livestock slaughtered</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => navigate("/visualizations/animals/shelter-animal-counts")}>
                    <List.Content>
                        <List.Header>Shelter animal counts</List.Header>
                        <List.Description>Yearly graph of intakes/outcomes of shelter animals</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => navigate("/visualizations/animals/sanctuaries")}>
                    <List.Content>
                        <List.Header>Animal sanctuaries</List.Header>
                        <List.Description>Map of animal sanctuaries around the world</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => navigate("/visualizations/animals/us-facilities")}>
                    <List.Content>
                        <List.Header>Animal industry facilities</List.Header>
                        <List.Description>Map of animal industry facilties in the United States</List.Description>
                    </List.Content>
                </List.Item>
            </List>
        </Segment>
    </>
);

export default AnimalsPage;
